/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andrisam
==============================================================================================*/
@import "sass-lib";
@media all and(max-width: 1360px) {

	.wrapper, .container {width: 860px;}

	.styledSelect {
		height: 40px; line-height: 40px;
		select {height: 40px; line-height: 40px;}  
		&:after {width: 15px; height: 12px; right: 10px;}  
	}
	
	.comingsoon {
		.container {width: 940px; padding: 0 50px;}   
		.cm-mid {
			padding: 140px 0 140px 0; 
			.title {font-size: 26px; line-height: 44px;}  
			p {padding: 0 0; font-size: 14px; line-height: 20px;}    
		}
	}
	
	.middle {
		.banner {
			&.v2 {
				.caption {
					.title {padding: 0 50px;}  
				}
			} 
		}
		.midcontent {
			.container {width: 860px;}    
			&.v3,
			&.v4 {
				.container {width: 860px; padding: 60px 0 70px 0;}     
			}			
		}	

		.list-row {
			&.v2 {
				.container {width: 860px; padding: 70px 0;}  
			} 			
		}

		.filtering {
			padding: 0;
			.fr {
				.fsearch {
					input[type="text"] {height: 40px; line-height: 40px;}   
				}
			}
		}

		.list-csr {
			.col {
				width: 48%; margin: 0 4% 4% 0;
				.caption {
					padding: 0 20px 20px 20px;  
					h4 {font-size: 24px;}
				}
				.date {
					font-size: 24px; padding: 20px 10px;   
					span {font-size: 19px;} 
				}
				.img { 
					&:before {background-size:100% 100% !important;}   
				}
			}  
		}

		.list-career {  
			.row {
				.col {
					width: 30.6%; margin: 0 4% 4% 0; 
					&:nth-child(4n+4) {margin-right: 4%;}  
					&:nth-child(3n+3) {margin-right: 0;} 
				}   
			}
		}

		.our-product {
			.row {
				.box {
					.col {
						&.text {min-height: 268px; max-height: 268px; margin-top: 0;}  
					}
				}
			}
		}

		.box-contact {
			&.page-contact {
				.container {width: 860px;} 	
			}
		}

	}
}